<template>
    <modal @submit="onSubmit" @close="close">
        <template #header>
            <div class="flex items-center">
                <Icon :icon="['far', 'clock']" class="mr-3 hidden sm:inline-block" />
                <h1 class="font-bold">{{ $t('professional.slot_modal._title') }}</h1>
            </div>
            <div class="sm:hidden">
                <button class="btn" :class="{ 'btn--disabled': invalid }">
                    {{ $t('common.save') }}
                </button>
            </div>
        </template>
        <template>
            <div class="mb-4 flex items-center">
                <div class="w-full">
                    <InputDate v-model="fields.date" name="date" />
                </div>
            </div>
            <div class="mb-4 flex items-center">
                <div class="pr-4">{{ $t('common.from') }}</div>
                <div class="flex-auto">
                    <InputTime v-model="fields.startTime" name="start_time" :date="fields.date" />
                </div>
                <div class="px-4">{{ $t('common.to') }}</div>
                <div class="flex-auto">
                    <InputTime v-model="fields.endTime" name="end_time" :date="fields.date" />
                </div>
            </div>
            <div v-if="isRecurring && isEdit" class="mb-4">
                <div class="mb-4">
                    {{ $t('professional.recurrence_update_modes.is_instance') }}
                </div>
                <div class="flex items-center">
                    <div class="mr-2 flex-none">
                        {{ $t('professional.recurrence_update_modes.when_saving_update') }}
                    </div>
                    <InputSelect
                        v-model="fields.recurrenceUpdateMode"
                        name="update_mode"
                        :options="recurrenceUpdateModes"
                    />
                </div>
            </div>
            <div
                v-if="!(isEdit && fields.recurrenceUpdateMode === 'this')"
                class="mb-4 flex items-center"
            >
                <InputCheckbox v-if="!isEdit" v-model="isRecurring" class="flex-none" />
                <div v-if="isRecurring" class="flex w-full items-center">
                    <span>{{ $t('common.repeat') }}</span>
                    <div class="mx-2 w-16">
                        <InputText
                            v-model.number="fields.recurrence_rule.count"
                            name="occurrences"
                            type="number"
                            min="1"
                            max="20"
                        />
                    </div>

                    <span>{{ $tc('common.occurrence', fields.recurrence_rule.count) }}</span>
                </div>
                <div v-else class="cursor-pointer" @click="isRecurring = true">
                    {{ $t('common.no_repeat') }}
                </div>
            </div>
            <div class="mb-2 mt-6 flex items-center text-lg">
                <div class="w-8 text-lg">
                    <Icon :icon="['far', 'ticket']" class="rotate-45 transform" />
                </div>
                <div>{{ $t('professional.slot_modal.voucher_types') }}</div>
            </div>
            <div class="mb-4">
                <InputSelectList
                    v-model="fields.selectedVoucherTypes"
                    name="voucher_types"
                    :options="voucherTypes"
                />
            </div>
            <div class="mb-4 flex items-center">
                <div class="w-8 text-lg">
                    <Icon :icon="['far', 'tag']" class="rotate-90 transform" />
                </div>
                <InputText
                    v-model="fields.title"
                    name="title"
                    :placeholder="$t('professional.slot_modal.title')"
                />
            </div>
            <div class="mb-4 flex items-center">
                <div class="w-8 text-lg">
                    <Icon :icon="['far', 'location-dot']" />
                </div>
                <InputText
                    v-model="fields.location"
                    name="location"
                    :placeholder="$t('professional.slot_modal.location')"
                />
            </div>
            <div class="mb-4 flex items-center">
                <div class="w-8 text-lg">
                    <Icon :icon="['far', 'pen']" />
                </div>
                <InputTextArea
                    v-model="fields.notes"
                    name="notes"
                    rows="2"
                    :placeholder="$t('professional.slot_modal.notes')"
                />
            </div>
            <div class="hidden justify-center sm:flex">
                <button
                    v-if="isEdit"
                    class="btn btn--danger mr-4"
                    @click.prevent="openSlotDeleteDialog()"
                >
                    {{ $t('common.delete') }}
                </button>
                <button class="btn" :class="{ 'btn--disabled': invalid }">
                    {{ $t('common.save') }}
                </button>
            </div>

            <div class="flex justify-center sm:hidden">
                <button
                    v-if="isEdit"
                    class="link-danger flex items-center"
                    @click.prevent="openSlotDeleteDialog()"
                >
                    <Icon :icon="['far', 'trash']" class="mr-2" />
                    <span>{{ $t('common.delete') }}</span>
                </button>
            </div>
        </template>
    </modal>
</template>

<script>
import { Vue, Component, Prop } from 'vue-property-decorator';
import Modal from '@/components/modals/Modal';
import InputText from '@/components/input/InputText';
import InputTextArea from '@/components/input/InputTextArea';
import InputSelect from '@/components/input/InputSelect';
import InputSelectList from '@/components/input/InputSelectList';
import InputDate from '@/components/input/InputDate';
import InputTime from '@/components/input/InputTime';
import InputCheckbox from '@/components/input/InputCheckbox';
import SlotDeleteDialog from '@/components/modals/SlotDeleteDialog.vue';
import {
    CALENDAR_EVENTS_FETCH,
    CALENDAR_SLOTS_FETCH,
    REQUEST,
    VALIDATION_CREATE_MESSAGE,
} from '@/constants';

import moment from 'moment-timezone';

@Component({
    components: {
        Modal,
        InputText,
        InputSelect,
        InputSelectList,
        InputDate,
        InputTime,
        InputTextArea,
        InputCheckbox,
    },
})
export default class SlotModal extends Vue {
    fields = {
        selectedVoucherTypes: [],
        recurrence_rule: {
            frequency: 'WEEKLY',
            count: 4,
        },
        recurrenceUpdateMode: 'this',
    };

    isRecurring = false;

    @Prop() event;

    mounted() {
        // Populate the modal on edit
        if (this.event) {
            this.fields = {
                ...this.fields,
                ...this.event,
                selectedVoucherTypes: this.event.voucher_types?.map((vt) => vt.id) || [],
            };

            if (this.event.recurrence_rule) {
                this.isRecurring = true;
            }
        }
    }

    get voucherTypes() {
        return this.$store.state.vouchers.voucherTypes.filter((vt) => vt.max_event_regs < 10);
    }

    get isEdit() {
        return this.event?.id;
    }

    get invalid() {
        const { date, startTime, endTime, selectedVoucherTypes } = this.fields;
        return !date || !startTime || !endTime || !selectedVoucherTypes.length;
    }

    close() {
        this.$store.dispatch(CALENDAR_EVENTS_FETCH);
        this.$store.dispatch(CALENDAR_SLOTS_FETCH);
        this.$root.$emit('modal-close');
    }

    async onSubmit() {
        if (this.invalid) return;
        if (this.loading) return;
        this.loading = true;

        const response = await this.$store.dispatch(REQUEST, {
            method: this.isEdit ? 'put' : 'post',
            url: this.isEdit ? `availability-slots/${this.event.id}` : 'availability-slots',
            data: {
                start_time: moment(this.fields.startTime)
                    .set({
                        year: this.fields.date.year(),
                        month: this.fields.date.month(),
                        date: this.fields.date.date(),
                        seconds: 0,
                    })
                    .utc(),
                end_time: moment(this.fields.endTime)
                    .set({
                        year: this.fields.date.year(),
                        month: this.fields.date.month(),
                        date: this.fields.date.date(),
                        seconds: 0,
                    })
                    .utc(),
                voucher_types: this.fields.selectedVoucherTypes,
                recurrence_rule: this.isRecurring ? this.fields.recurrence_rule : null,
                update_mode: this.fields.recurrenceUpdateMode,
                title: this.fields.title,
                location: this.fields.location,
                notes: this.fields.notes,
            },
        });

        this.loading = false;

        if (response.status === 201) {
            this.$store.dispatch(VALIDATION_CREATE_MESSAGE, {
                type: 'success',
                message: 'professional.slot_modal.create_success',
            });
            this.close();
        }

        if (response.status === 200) {
            this.$store.dispatch(VALIDATION_CREATE_MESSAGE, {
                type: 'success',
                message: 'professional.slot_modal.update_success',
            });
            this.close();
        }
    }

    openSlotDeleteDialog() {
        this.$root.$emit('modal-open', {
            modal: SlotDeleteDialog,
            props: {
                event: this.event,
                callback: () => {
                    this.close();
                },
            },
        });
    }

    get recurrenceUpdateModes() {
        return [
            { value: 'this', label: this.$t('professional.recurrence_update_modes.this') },
            { value: 'future', label: this.$t('professional.recurrence_update_modes.future') },
            // { value: 'all', label: this.$t('professional.recurrence_update_modes.all') },
        ];
    }
}
</script>
