import {
    REQUEST,
    CALENDAR_CALENDARS_FETCH,
    CALENDAR_CALENDARS_SET,
    CALENDAR_SELECTED_CALENDARS_SET,
    CALENDAR_EVENTS_FETCH,
    CALENDAR_EVENTS_SET,
    CALENDAR_LOADING_SET,
    CALENDAR_SLOTS_FETCH,
    CALENDAR_SLOTS_SET,
    PROFESSIONALS_FETCH,
    CALENDAR_PARAMS_SET,
    CALENDAR_DRAFT_EVENT_SET,
    DATE_FORMAT,
    CALENDAR_BOOK_SLOT,
    CALENDAR_REGISTER,
    VALIDATION_CREATE_MESSAGE,
    CALENDAR_SHOWN_DAYS_SET,
} from '@/constants';

import moment from 'moment-timezone';

const localizeDateTimes =
    (timezone) =>
    ({ start_time, end_time }) => {
        timezone = timezone || moment.tz.guess();
        return {
            date: moment.utc(start_time).tz(timezone).startOf('day'),
            startTime: moment.utc(start_time).tz(timezone),
            endTime: moment.utc(end_time).tz(timezone),
        };
    };

export default {
    state: {
        shownDays: 5,
        calendars: [],
        selectedCalendars: [],
        events: [],
        loading: false,
        slots: [],
        params: {
            start_date: moment().subtract(2, 'weeks').format(DATE_FORMAT),
            end_date: moment().add(2, 'weeks').format(DATE_FORMAT),
        },
        draftEvent: null,
    },
    getters: {
        isCalendarLoading: (state) => {
            return state.loading;
        },
    },
    mutations: {
        [CALENDAR_SHOWN_DAYS_SET](state, shownDays) {
            state.shownDays = shownDays;
        },
        [CALENDAR_CALENDARS_SET](state, calendars) {
            state.calendars = calendars;
        },
        [CALENDAR_SELECTED_CALENDARS_SET](state, selectedCalendars) {
            state.selectedCalendars = selectedCalendars;
        },
        [CALENDAR_EVENTS_SET](state, events) {
            state.events = events;
        },
        [CALENDAR_LOADING_SET](state, loadingState) {
            state.loading = loadingState;
        },
        [CALENDAR_SLOTS_SET](state, slots) {
            state.slots = slots;
        },
        [CALENDAR_PARAMS_SET](state, params) {
            state.params = params;
        },
        [CALENDAR_DRAFT_EVENT_SET](state, draftEvent) {
            state.draftEvent = draftEvent;
        },
    },
    actions: {
        async [CALENDAR_CALENDARS_FETCH]({ commit, dispatch, rootGetters }) {
            let calendars = [];
            let selectedCalendars = [];

            if (rootGetters.isThemeClient) {
                const res = await dispatch(PROFESSIONALS_FETCH, {
                    status: 'confirmed',
                });

                if (res.status === 200) {
                    calendars = res.data.data.map((pro) => {
                        return {
                            id: pro.id,
                            //Name needs to start with a space so its loaded correctly in CalendarFilter.vue
                            name: ` ${pro.full_name}`,
                            text: 'client.calendar.dropdown.of',
                        };
                    });

                    selectedCalendars = res.data.data.map((pro) => pro.id);
                }
            } else {
                calendars = [
                    { id: 1, text: 'prof.calendar.dropdown.events', name: '' },
                    { id: 2, text: 'prof.calendar.dropdown.availability', name: '' },
                ];
                selectedCalendars = [1, 2];
            }

            commit(CALENDAR_CALENDARS_SET, calendars);
            commit(CALENDAR_SELECTED_CALENDARS_SET, selectedCalendars);
        },
        async [CALENDAR_EVENTS_FETCH]({ commit, dispatch, state, rootState, rootGetters }, params) {
            if (params?.start_date && params?.end_date) {
                commit(CALENDAR_PARAMS_SET, params);
            } else {
                params = state.params;
            }

            const res = await dispatch(REQUEST, { url: 'events', params });

            if (res.status === 200) {
                const events = res.data.data.map((event) => {
                    return rootGetters.isThemeClient
                        ? {
                              ...event,
                              ...localizeDateTimes(rootState.profile.user.timezone)(event),
                              type: event.voucher_type_id,
                              professional:
                                  rootState.professionals.professionals[event.professional_id],
                              eventRegistration: event.event_registrations
                                  ?.sort((e1, e2) =>
                                      moment(e2.created_at).diff(moment(e1.created_at)),
                                  )
                                  .find(
                                      (registration) =>
                                          registration.client_id ===
                                          rootState.profile.user?.client?.id,
                                  ),
                          }
                        : {
                              ...event,
                              ...localizeDateTimes(rootState.profile.user.timezone)(event),
                              type: event.voucher_type_id,
                          };
                });
                commit(CALENDAR_EVENTS_SET, events);
            }
        },
        async [CALENDAR_SLOTS_FETCH]({ commit, dispatch, state, rootState, rootGetters }, params) {
            if (params?.start_date && params?.end_date) {
                commit(CALENDAR_PARAMS_SET, params);
            } else {
                params = state.params;
            }

            const res = await dispatch(REQUEST, {
                url: 'availability-slots',
                params,
            });

            if (res.status === 200) {
                const slots = res.data.data.map((slot) => {
                    return rootGetters.isThemeClient
                        ? {
                              ...slot,
                              ...localizeDateTimes(rootState.profile.user.timezone)(slot),
                              blocked: slot.blocked.map(
                                  localizeDateTimes(rootState.profile.user.timezone),
                              ),
                              type: -1,
                              professional:
                                  rootState.professionals.professionals[slot.professional_id],
                          }
                        : {
                              ...slot,
                              ...localizeDateTimes(rootState.profile.user.timezone)(slot),
                              blocked: slot.blocked.map(
                                  localizeDateTimes(rootState.profile.user.timezone),
                              ),
                              type: -1,
                          };
                });
                commit(CALENDAR_SLOTS_SET, slots);
            }
        },
        async [CALENDAR_BOOK_SLOT]({ dispatch, rootState }, params) {
            const response = await dispatch(REQUEST, {
                method: 'post',
                // Always use the client/ prefix for this request, even on guest pages.
                baseURL: rootState.config.rules.api.url + 'client/',
                url: 'event-registrations',
                data: {
                    availability_slot_id: params.event.id,
                    start_time: moment(params.fields.startTime)
                        .set({
                            year: params.fields.date.year(),
                            month: params.fields.date.month(),
                            date: params.fields.date.date(),
                        })
                        .utc(),
                    duration: params.fields.selectedDuration,
                    voucher_type_id: params.fields.selectedVoucherType,
                },
                hideToast: true,
            });

            if (response.status === 201) {
                dispatch(VALIDATION_CREATE_MESSAGE, {
                    type: 'success',
                    message: 'client.slot_details_modal.claimed',
                });
            } else if (response.data?.data?.errors[0]?.field === 'end_time') {
                dispatch(VALIDATION_CREATE_MESSAGE, {
                    type: 'error',
                    message: 'client.slot_details_modal.error_end_time_message',
                });
            }

            return response;
        },
        async [CALENDAR_REGISTER]({ dispatch, rootState }, params) {
            const response = await dispatch(REQUEST, {
                method: 'post',
                // Always use the client/ prefix for this request, even on guest pages.
                baseURL: rootState.config.rules.api.url + 'client/',
                url: `events/${params.event.id}/register`,
                data: {
                    voucher_type_id: params.event.voucher_type_id,
                },
            });

            if (response.status === 201) {
                dispatch(VALIDATION_CREATE_MESSAGE, {
                    type: 'success',
                    message: 'client.class_details_modal.registered',
                });
            }
            return response;
        },
    },
};
