<template>
    <div>
        <div
            ref="dialog"
            class="fixed inset-0 z-40 flex flex-col items-center justify-center overflow-hidden"
            tabindex="0"
            @keydown.esc="close()"
        >
            <transition enter-class="opacity-0" leave-to-class="opacity-0">
                <div
                    v-if="ready"
                    class="absolute inset-0 bg-black bg-opacity-50 px-2 transition-all duration-500"
                    @click.prevent="close"
                ></div>
            </transition>
            <transition
                enter-class="translate-y-full opacity-0"
                enter-active-class="transition-all duration-500 transform"
                leave-to-class="translate-y-full opacity-0"
                leave-active-class="transition-all duration-500 transform"
            >
                <form
                    v-if="ready"
                    class="relative w-full max-w-lg overflow-hidden rounded-lg shadow"
                    @submit.prevent="$emit('submit')"
                >
                    <div
                        class="flex items-center justify-between bg-neutral-darkest p-6 text-white"
                    >
                        <div class="flex items-center">
                            <Icon :icon="['far', 'user']" class="mr-3" />
                            <slot name="title">
                                <h1 class="font-bold">
                                    {{ title }}
                                </h1>
                            </slot>
                        </div>
                        <button type="button" class="focus:outline-none" @click.prevent="close">
                            <Icon :icon="['far', 'xmark']" class="block" />
                        </button>
                    </div>
                    <div class="bg-white p-6">
                        <slot name="default">
                            <div class="flex items-center justify-center">
                                <button
                                    class="btn mx-3 justify-self-center p-3 text-center"
                                    @click.prevent="close"
                                >
                                    {{ $t('common.no') }}
                                </button>
                                <button class="btn mx-3 justify-self-center p-3 text-center">
                                    {{ $t('common.yes') }}
                                </button>
                            </div>
                        </slot>
                    </div>
                </form>
            </transition>
        </div>
        <a tabindex="0" class="fixed top-0 overflow-hidden" @focus="$refs.dialog.focus()" />
    </div>
</template>

<script>
import { Vue, Component, Prop } from 'vue-property-decorator';
import { HELPERS_SLEEP } from '@/constants';

@Component
export default class Dialog extends Vue {
    ready = false;

    @Prop() title;

    mounted() {
        this.ready = true;
        this.$refs.dialog.focus();
    }

    created() {
        // Close dialog when navigating back
        const unregisterRouterGuard = this.$router.beforeEach((_, __, next) => {
            this.close();
            next();
        });
        this.$once('hook:destroyed', () => {
            unregisterRouterGuard();
        });
    }

    async close() {
        this.ready = false;
        await this.$store.dispatch(HELPERS_SLEEP, 500);
        this.$emit('close');
    }
}
</script>
