<template>
    <div class="overflow-x-hidden transition-all duration-500" style="max-height: 15rem">
        <div v-if="!options">{{ $t('common.loading') }}</div>
        <div v-else-if="!options.length">{{ placeholder }}</div>
        <template v-else>
            <div
                v-for="option in options"
                :key="option.id"
                class="my-1 flex cursor-pointer items-center rounded-lg bg-neutral-lighter bg-opacity-25 p-3 hover:bg-primary-lighter"
                @click="toogleOption(option.id)"
            >
                <div
                    class="relative mr-3 h-4 w-4 flex-none cursor-pointer rounded border border-neutral-lighter transition-all duration-500"
                    :class="{
                        'border-primary bg-primary': value.includes(option.id),
                    }"
                >
                    <Icon
                        :icon="['far', 'check']"
                        class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform text-xs text-transparent"
                        :class="{
                            'text-white': value.includes(option.id),
                        }"
                    />
                </div>
                <slot v-bind="option">
                    <span>{{ option[label || 'label'] }}</span>
                </slot>
            </div>
        </template>
    </div>
</template>

<script>
import { Vue, Component } from 'vue-property-decorator';
import { VALIDATION_REMOVE_ERROR } from '@/constants';

@Component({
    props: {
        name: String,
        options: Array,
        label: String,
        placeholder: {
            default: 'No options to select from.',
            type: String,
        },
        scope: {
            default: 'default',
            type: String,
        },
        value: { type: Array, default: () => [] },
    },
})
export default class InputSelectList extends Vue {
    focused = false;

    toogleOption(id) {
        if (this.$store.getters.hasError(this.name, this.scope)) {
            this.$store.commit(VALIDATION_REMOVE_ERROR, {
                field: this.name,
                scope: this.scope,
            });
        }

        if (this.value.includes(id)) {
            this.$emit(
                'input',
                this.value.filter((v) => v !== id),
            );
        } else {
            this.$emit('input', [...this.value, id]);
        }
    }
}
</script>
