import Vue from 'vue';
import Vuex from 'vuex';

import { HELPERS_SLEEP, PROFILE_LOAD } from '@/constants';

import request from './modules/request';
import validation from './modules/validation';
import config from './modules/config';
import onboarding from './modules/onboarding';
import register from './modules/register';
import auth from './modules/auth';
import profile from './modules/profile';
import clients from './modules/clients';
import professionals from './modules/professionals';
import vouchers from './modules/vouchers';
import calendar from './modules/calendar';
import emails from './modules/emails';
import pwaInstallPrompt from './modules/pwaInstallPrompt';
import invite from './modules/invite';

Vue.use(Vuex);

const store = new Vuex.Store({
    modules: {
        request,
        validation,
        config,
        register,
        auth,
        profile,
        clients,
        professionals,
        vouchers,
        calendar,
        onboarding,
        emails,
        invite,
        pwaInstallPrompt,
    },
    actions: {
        [HELPERS_SLEEP](context, time = 1000) {
            return new Promise((resolve) => setTimeout(resolve, time));
        },
    },
});

store.dispatch(PROFILE_LOAD);

export default store;
